<template>
  <div class="address">
    <div class="navbar-box">
      <van-nav-bar left-arrow @click-left="onClickLeft">
        <template #left>
          <van-icon name="arrow-left" size="20" color="#000000" />
        </template>
        <template #title>
          <div class="navbar-title">Receiving address</div>
        </template>
      </van-nav-bar>
    </div>

    <div class="content" @click="to_add_dddress">
      <van-image width="100%" fill="contain" :src="require('./img/img.png')" />
      <div class="bg"></div>
    </div>

    <div class="btn" @click="to_add_dddress">＋Add address</div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
    to_add_dddress() {
      this.$router.push({ path: "/add-dddress" });
    },
  },
};
</script>

<style lang="scss">
.address {
  background-color: #f1f1f1;
  min-height: 100vh;

  .navbar-box {
    position: sticky;
    top: 0;
    border-radius: 0 0 0.25rem 0.25rem;
    overflow: hidden;
    z-index: 999;
    border-bottom: 1px solid #ffffff;

    .van-nav-bar__content {
      height: 5.5rem;
    }

    .navbar-title {
      display: flex;
      align-items: center;
      font-size: 2.25rem;
      color: #000000;
      font-weight: bold;
      position: relative;
    }
  }

  .content {
    position: relative;
    padding: 1.25rem 1.5rem 0;

    .bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .btn {
    width: 43.875rem;
    height: 5rem;
    background: #e5285b;
    border-radius: 0.25rem;
    font-weight: 500;
    font-size: 1.875rem;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 2.5rem;
    left: calc((100% - 43.875rem) / 2);
  }
}
</style>